<template>
  <v-container fluid class="profile-section">
    <!-- v-if="!$vuetify.breakpoint.smAndDown" -->
    <Breadcrumb :items="breadCrumbs" />
    <router-view></router-view>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/navigation/Breadcrumb";
export default {
  name: "Profile",
  components: { Breadcrumb },
  computed: {
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const breadCrumbs = [{ to: "/", text: "Homepage", exact: true }];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      // let lastIndexFound = 0;
      for (let i = 0; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            to: breadcrumb,
            exact: true,
            // i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
          });
          // lastIndexFound = i;
          // breadcrumb = "";
        }
      }
      return breadCrumbs;
    }
  }
};
</script>
